<script setup>
import { computed, inject } from 'vue';
import SubscriptionService from '@/services/SubscriptionService'
import { useStore } from 'vuex'
import _ from 'lodash'
import FormButton from '../../components/Atoms/FormButton.vue'
import Tooltip from '../Atoms/Tooltip.vue';
import Badge from '../../components/Badge.vue';

const props = defineProps({
  plan: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  },
  isCart: {
    type: Boolean
  },
  productType: {
    type: String
  }
})

const store = useStore()

const { updateSelectedPaymentMethod, selectPackage } = inject('selectedPaymentMethod')

// Computed
const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])

const price = computed(() => props.isYearly ? props.plan.info?.yearly : props.plan.baseCharge)

const labelComputed = computed(() => {
  if (props.plan?.name === 'Custom') return 'Contact Us'
  return isCurrentSubscription.value ? 'Active plan' : 'Subscribe Now'
})

const isCurrentSubscription = computed(() => {
  const subscription = props.productType === 'ott'
    ? addonSubscriptions.value.find(subscription => subscription.category === 'ott' && subscription.enabled)
    : addonSubscriptions.value.find(subscription => subscription.category === 'live' && subscription.enabled)
  return (subscription?.package?._id === props.plan?._id) && props.plan.name !== 'Custom'
})

const allowOtt = computed(() => {
  const currentSubscription = addonSubscriptions.value.find(subscription => subscription.category === 'live' && subscription.enabled)
  return (currentSubscription?.package?.baseCharge > 750 && currentSubscription?.package?.cycleAge >= 365) || (currentSubscription?.package.baseCharge > 100 && currentSubscription?.package.cycleAge < 365)
})

const isDisabled = computed(() => isCurrentSubscription.value || (!allowOtt.value && props.productType === 'ott'))
// Methods
const selectSubscriptionPackage = async (pack) => {
  selectPackage(pack)
  const checkpaymentMethods = await SubscriptionService.getUserPaymentMethods()
  updateSelectedPaymentMethod(checkpaymentMethods[0])
}
</script>

<template>
  <header class="bg-light-1 !px-3 !py-4 border-b border-surface-9 h-[220px] flex flex-col relative"
    :class="{ 'rounded-t-md': isCart }">
    <Badge v-if="props.plan?.info?.popular" type="blue" class="absolute !text-[10px] font-medium top-1"
      title="POPULAR" />
    <h2 class="text-[15px] font-bold text-surface-1 !my-4">{{ props.plan?.info?.name ? props.plan?.info?.name :
      props.plan?.name }}</h2>
    <p v-if="props.plan.baseCharge" class="flex items-end my-2">
      <span class="text-xl text-surface-1 font-medium leading-4">
        ${{ price ? price : props.plan?.baseCharge }}
      </span>
      <span class="text-surface-5 text-sm leading-4">
        /month
      </span>
    </p>
    <template v-if="props.isYearly && props.plan.baseCharge">
      <p class="text-tiny text-surface-5">Billed as ${{ props.plan.baseCharge }} yearly</p>
      <p class="text-tiny text-yellow">Saved ${{ props.plan.info?.saved }}</p>
    </template>
    <template v-if="!isCart">
      <Tooltip wrapperClass="block justify-center mt-auto" v-if="props.plan?.name === 'Custom'" :active="isDisabled"
        content="Minimum Premium plan is required to subscribe to OTT plan">
        <span>
          <FormButton class="justify-center"
            :customClasses="[isDisabled ? '!border-surface-6 text-surface-6 hover:text-surface-6 pointer-events-none' : '!border-main text-main hover:!border-main-light hover:text-main-light', 'h-8 flex items-center justify-center rounded-md text-tiny !border hover:no-underline']"
            :disabled="isDisabled" full-width type='main-outline' :label="labelComputed"
            href="https://calendly.com/support-sales/castr" target="_blank" />
        </span>
      </Tooltip>
      <Tooltip wrapperClass="block justify-center mt-auto" v-else :active="isDisabled && !isCurrentSubscription"
        content="Minimum Premium plan is required to subscribe to OTT plan">
        <FormButton :disabled="isDisabled" full-width class="justify-center" :label="labelComputed"
          @click="selectSubscriptionPackage(props.plan)" />
      </Tooltip>
    </template>
  </header>
</template>

<style></style>
