<script setup>
import _ from 'lodash'
import { useRouter } from 'vue-router'
import FormButton from '../../components/Atoms/FormButton.vue'
import FormSwitch from '../../components/Atoms/FormSwitch.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconChevronDown from '../../components/icon/IconChevronDown.vue';
import IconCheckmark from '../icon/IconCheckmark.vue'
import SubscribePlanItem from './SubscribePlanItem.vue';
import SubscribePlansCustomWidget from './SubscribePlansCustomWidget.vue';
import IconChevronRight from '../icon/IconChevronRight.vue'

const props = defineProps({
  subscriptionPackages: {
    type: Array,
  },
  plansData: {
    type: Array,
  },
  productType: {
    type: String,
  },
  shouldShowCompare: {
    type: Boolean
  }
})

const router = useRouter()

const yearly = defineModel('yearly')

//Methods
const goToSubscribe = () => router.push({ path: '/subscribe' })

</script>

<template>
  <div class="relative">
    <div
      class="grid grid-cols-1 lg:grid-rows-[auto_1fr_auto] rounded-xl w-full lg:w-10/12 mx-auto divide-x divide-surface-9"
      :class="productType === 'ott' ? 'lg:grid-cols-4' : 'lg:grid-cols-5'">
      <div
        class="lg:col-span-5 flex items-center justify-center gap-x-3 bg-surface-3 !p-4 rounded-t-xl text-tiny sticky lg:static top-16 ring-1 ring-surface-3">
        <span>Monthly</span>
        <FormSwitch v-model="yearly" />
        <span class="font-medium"><span>Yearly</span> <span class="text-orange">- Save upto {{ productType === 'ott' ? '20%' : '37% + upfront bandwidth + more streams' }}</span></span>
      </div>
      <SubscribePlanItem v-for="(plan, index) in plansData" :key="index" :plan="plan" :is-yearly="yearly"
        :product-type="productType" />
      <div v-if="productType === 'ott'" class="lg:col-span-5 grid grid-cols-1 lg:grid-cols-2 bg-white py-2 border-t border-surface-9 text-surface-1 text-sm !px-3 leading-6">
        <ul class="flex items-center flex-wrap gap-x-2 md:justify-center">
          <li class="flex items-center gap-x-2">
            <figure class="bg-success mx-auto flex items-center justify-center size-4 rounded-full">
              <IconBase classIcon="text-white" width="12px" height="12px">
                <IconCheckmark />
              </IconBase>
            </figure>
            Live Streaming Bandwidth
          </li>
          <li class="flex items-center gap-x-2">
            <figure class="bg-success mx-auto flex items-center justify-center size-4 rounded-full">
              <IconBase classIcon="text-white" width="12px" height="12px">
                <IconCheckmark />
              </IconBase>
            </figure>
            Video Hosting Storage
          </li>
        </ul>
        <div>
          <p class="font-medium">Based on Castr live streaming tiers.
            <FormButton customClasses="inline-flex items-center text-main hover:text-main-light hover:no-underline" to="/pricing">
              See Pricing
              <icon-base>
                <IconChevronRight />
              </icon-base>
            </FormButton>
          </p>
          <p class="font-medium text-surface-5">* Minimum Premium Tier Required.</p>
        </div>
      </div>
      <div class="lg:col-span-5 flex items-center justify-center bg-white rounded-b-xl py-2 border-t border-surface-9">
        <FormButton v-if="shouldShowCompare" target="_self" href="app/subscribe#compare"
          customClasses="text-surface-1 text-tiny flex items-center gap-x-2">
          Compare Plans
          <IconBase>
            <IconChevronDown />
          </IconBase>
        </FormButton>
        <FormButton v-else @click="goToSubscribe" customClasses="text-surface-1 text-tiny flex items-center gap-x-2">
          See full features
          <IconBase>
            <IconChevronDown />
          </IconBase>
        </FormButton>
      </div>
    </div>
    <SubscribePlansCustomWidget class="absolute left-[87%] top-20 !hidden lg:!block" />
  </div>
</template>

<style></style>
