<script setup>
import { computed } from 'vue';
import _ from 'lodash'
import SubscribePlanItemCompareCell from './SubscribePlanItemCompareCell.vue';
import SubscribePlanItemCompareHeader from './SubscribePlanItemCompareHeader.vue';

const props = defineProps({
  row: {
    type: Array,
  },
  isYearly: {
    type: Boolean
  },
  productType: {
    type: String
  }
})

const rowComputed = computed(() => {
  if (props.row.dataYearly && props.isYearly) return props.row.dataYearly
  return props.row.data
})

const customClasses = (row) => {
  if (row.colspan) return `lg:basis-[80%] colspan-${row.colspan}`
  return 'lg:basis-[16%]'
}

</script>

<template>
	<section class="col-span-6 even:bg-white odd:bg-light-1 text-surface-1 text-tiny font-medium flex w-full divide-x divide-surface-9 border-b border-surface-9 !border-l-0">
    <SubscribePlanItemCompareHeader class="basis-[20%]  shrink-0 grow-0 !hidden lg:!block" :key="index" :data="row" :is-yearly="props.isYearly" />
    <SubscribePlanItemCompareCell class="basis-[50vw] shrink-0 grow-0" v-for="(data, index) in rowComputed" :key="index" :data="data" :class="customClasses(row)" />
  </section>
</template>

<style>
.colspan-5:nth-child(n+3) {
  @apply lg:!hidden
}
</style>
