<script setup>
import _ from 'lodash'
import SubscribePlanItemHeader from './SubscribePlanItemHeader.vue';
import SubscribePlanItemDescription from './SubscribePlanItemDescription.vue';

const props = defineProps({
  plan: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  },
  productType: {
    type: String
  }
})

</script>

<template>
	<section class="bg-white">
    <SubscribePlanItemHeader :plan="props.plan" :is-yearly="props.isYearly" :product-type="productType" />
    <SubscribePlanItemDescription :plan="props.plan" :is-yearly="props.isYearly" />
  </section>
</template>

<style>
</style>
