<script setup>
import _ from 'lodash'

const props = defineProps({
  plan: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  },
  isCart: {
    type: Boolean
  }
})

</script>

<template>
	<ul class="bg-white text-surface-1 !p-3 text-sm flex flex-col gap-y-2":class="{'rounded-b-md': isCart}">
    <li v-for="(item, index) in props.plan.charectiristics" :key="index" v-html="isYearly ? item.name : (item.monthly || item.name)" :class="{'text-main font-medium': item.accent}">
    </li>
  </ul>
</template>

<style>
</style>
