<script setup>
import { computed } from 'vue';
import _ from 'lodash'
import IconCheckmark from '../../components/icon/IconCheckmark.vue';
import IconBase from '../../components/icon/IconBase.vue';

const props = defineProps({
  data: {
    type: Object,
  }
})

const content = computed(() => {
  if (props.data === false) return ''
  return props.data
})
</script>

<template>
  <div class="p-2 min-h-10 h-12 lg:h-auto flex items-center justify-center">
    <figure v-if="props.data === true" class="bg-success mx-auto flex items-center justify-center size-4 rounded-full">
      <IconBase classIcon="text-white" width="12px" height="12px">
        <IconCheckmark />
      </IconBase>
    </figure>
    <p class="text-center leading-none" v-else-if="content" v-html="content" />
  </div>
</template>

<style></style>
