<script setup>
import { ref, computed, watch, getCurrentInstance } from 'vue'
import _ from 'lodash'
import { useNotification } from "@kyvg/vue3-notification"
import MetricsService from '@/services/MetricsService'
import UserService from '@/services/UserService'
import SubscriptionService from '@/services/SubscriptionService'
import { useStore } from 'vuex'
import FormButton from '../../components/Atoms/FormButton.vue'
import TabGroup from '../../components/Atoms/TabGroup.vue'
import TabItem from '../../components/Atoms/TabItem.vue'
import Badge from '../../components/Badge.vue'
import Spinner from '../../components/ui/Spinner.vue'
import { date, bytes } from '../../filtersNew'
import SubscriptionCancelModal from '../../components/modals/SubscriptionCancelModal.vue'
import AlertModal from '../../components/modals/AlertModal.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconDownload from '../../components/icon/IconDownload.vue'

// Store, route, notification
const store = useStore()
const { notify } = useNotification()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

// Computed properties
const subscriptions = computed(() => store.getters['User/subscriptions'])

const userEmail = computed(() => store.getters['User/userEmail'])

const userId = computed(() => store.getters['User/userId'])

const isTeamModerator = computed(() => store.getters['User/isTeamModerator'])

const invoiceItems = ref(null)

const loadingStatus = ref(true)

const subscriptionsFiltered = computed(() => {
  if (activeTab.value === 'active') {
    const subscriptionsSorted = _.orderBy(subscriptions.value, ['category', 'parentPackage'], ['asc', 'desc'])
    return subscriptionsSorted.filter(subscription => subscription.enabled)
  } else {
    const subscriptionsSorted = _.orderBy(subscriptions.value, ['category', 'parentPackage'], ['desc', 'asc'])
    return subscriptionsSorted.filter(subscription => !subscription.enabled)
  }
})

// Refs
const activeTab = ref('active')
const configurableSubscription = ref(null)

//Methods
const setActiveTab = (tabName) => activeTab.value = tabName

const formatedDate = (endDate) => date(endDate, 'DD MMM, YYYY')

const isBundled = (sub) => sub.parentPackage

const subscriptionCirle = (sub) => {
  if (sub.package.cycleAge === 30) return 'month'
  if (sub.package.cycleAge === 365) return 'year'
  return
}

const subCategory = (sub) => {
  if (sub.package.category === 'live') return 'Live Streaming'
  if (sub.package.category === 'restream') return 'Mustistream'
  if (sub.package.category === 'vod') return 'Video Hosting'
  if (sub.package.category === 'ipcam') return 'IP Camera Restreaming'
  if (sub.package.category === 'scheduled') return 'Scheduler'
  if (sub.package.category === 'ott') return 'OTT'
  return sub.package.category
}

const getSubscriptionDefProp = (sub, prop) => {
  return _.get(sub, 'definitionOverride.' + prop) || _.get(sub, 'package.definition.' + prop)
}

const getSubscriptionUsageData = async () => {
  try {
    let rawInvoices = await SubscriptionService.getUserInvoicePayments()
    const paidInvoices = _.filter(rawInvoices, item => {
      return _.isEmpty(_.get(item, 'nested', 'invoice-payments-list'), false) === false
    })

    let payments = []
    _.each(paidInvoices, (invoice, idx) => {
      const invoiceTitle = _.get(invoice, ['nested', 'invoice-items', '0', 'item_title'], 'N/A')
      const paymentsList = _.get(invoice, ['nested', 'invoice-payments-list'], [])
      payments.push(_.map(paymentsList, elem => {
        let pdfLink = elem['pdf-invoice-link']
        if (elem.paysys_id === 'fastspring-contextual' && _.startsWith(elem.transaction_id, 'CASTR')) {
          pdfLink = `https://castrhq.onfastspring.com/account/order/${elem.transaction_id}/invoice/pdf`
        }

        return {
          date: elem.dattm,
          products: invoiceTitle,
          amount: `$${elem.amount}`,
          invoice: pdfLink
        }
      }))
    })

    payments = _.flatten(payments)
    invoiceItems.value = _.orderBy(payments, 'date', 'desc')
  } catch (e) { console.log('e', e) }
  await Promise.all(subscriptions.value.map(async (sub) => {
    const phoneAlerts = getSubscriptionDefProp(sub, 'phoneAlerts')
    if (phoneAlerts) {
      try {
        const res = (sub.package.category === 'restream')
          ? await MetricsService.getSubscriptionNotificationCount('restream')
          : await MetricsService.getSubscriptionNotificationCount()

        sub.package.category === 'restream'
          ? await store.dispatch('User/updateBaseSubUsage', { sub, phoneAlerts: { value: res ? res.sms : 0, unit: 'SMS' } })
          : await store.dispatch('User/updateAddonSubUsage', { sub, phoneAlerts: { value: res ? res.sms : 0, unit: 'SMS' } })

      } catch (e) {
        console.error('e', e)
      }
    }

    const bwLimit = getSubscriptionDefProp(sub, 'bandwidth')
    if (bwLimit) {
      try {
        const res = await MetricsService.getSubscriptionBandwidth(
          userId.value,
          sub.package._id
        )
        let currentBytes = res ? res.bytes : 0
        currentBytes = currentBytes >= 100000000 ? currentBytes : 0

        // Special case for specific user
        const userInfo = await UserService.getUserInfo()
        if (userInfo._id === '65a508f3e2026e6faf40dfdc') {
          currentBytes = currentBytes + 1024 * 1024 * 1024 * 1024 * 29
        }
        await store.dispatch('User/updateAddonSubUsage', { sub, bandwidth: bytes(currentBytes, true, 3, true) })

      } catch (e) {
        console.error('e', e)
      }
    }

    const storageLimit = getSubscriptionDefProp(sub, 'storage')
    if (storageLimit) {
      try {
        const res = await MetricsService.getSubscriptionStorage(
          UserService.getUserId(userId.value),
          sub.package._id
        )
        let currentBytes = res ? res.bytes : 0

        if (sub.category === 'vod' || sub.category === 'live') {
          let res2 = await MetricsService.getUserDvrStorage()
          currentBytes += res2 ? res2.bytes : 0
        }
        await store.dispatch('User/updateAddonSubUsage', { sub, storage: bytes(currentBytes, true, 3, true) })

      } catch (e) {
        console.error('e', e)
      }
    }
  }))
}

const assignedFormatted = (value, property) => {
  if (property === 'phoneAlerts') return `${value} SMS`
  if (property === 'storage') {
    return value < 1
      ? `${value *= 1000} MB`
      : `${value} GB`
  }
  if (property === 'bandwidth') {
    return value < 1
      ? `${value *= 1000} MB`
      : (value > 1000)
        ? `${(value / 1000).toFixed(3)} TB`
        : `${value} GB`
  }
}

const requestChangePaymentInfo = async () => {
  try {
    const result = await SubscriptionService.changePaymentInfo()
    if (result && result.ok === false && !result.link) {
      root.$emit('bv::show::modal', 'modal-change-payment-info-failed');
    }
    if (result && result.link) {
      window.location.href = result.link
    }
  } catch (e) {
    notify({
      group: 'error',
      text: 'Failed to request change payment info'
    });
  }
}

const requestUnsubscribe = (sub) => {
  configurableSubscription.value = sub
  root.$emit('bv::show::modal', 'modal-unsubscribe-package')
}

const unsubscribeSubCategory = async (cancelObj) => {
  const packCategory = configurableSubscription.value.category || 'restream'

  try {
    configurableSubscription.value.unsubscribeProcessing = true
    await SubscriptionService.unsubscribeSubscription(packCategory, cancelObj?.reason, cancelObj?.comment)
    configurableSubscription.value.unsubscribed = true
    configurableSubscription.value.unsubscribeProcessing = false
    await store.dispatch('User/loadUser')
    await getSubscriptionUsageData()
    notify({ group: 'success', text: `unsubscribed ${packCategory} package successfully` })
  } catch (e) {
    notify({ group: 'error', text: `failed to unsubscribe ${packCategory} package` })
  }
}

const getSubscriptionName = () => {
  if (!configurableSubscription.value) return
  let packname = configurableSubscription.value.package.name
  packname = _.startCase(packname)
  return packname
}

//Hooks
watch(subscriptions, async (newValue, oldValue) => {
  if ((newValue?.length != oldValue?.length) && (newValue?.length > 0)) {
    await getSubscriptionUsageData()
    loadingStatus.value = false
  }
  loadingStatus.value = false
}, {
  immediate: true
})
</script>

<template>
  <div>
    <Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <div v-else-if="!isTeamModerator">
      <header class="w-full flex flex-wrap items-center gap-x-2 !mt-2">
        <h2 class="text-xl font-medium">Subscriptions</h2>
        <span class="text-surface-7 lg:ml-auto text-sm">Email: <span class="text-surface-9">{{ userEmail
            }}</span></span>
        <FormButton type="secondary" @click="requestChangePaymentInfo()">
          Change Payment Details
        </FormButton>
      </header>
      <main class="!py-4">
        <section class="!mb-4">
          <TabGroup>
            <TabItem :isActive="activeTab === 'active'" label="Active" @click="setActiveTab('active')" />
            <TabItem :isActive="activeTab === 'expired'" label="Expired" @click="setActiveTab('expired')" />
          </TabGroup>
        </section>
        <section v-if="subscriptionsFiltered.length > 0">
          <ul class="flex flex-col gap-y-4">
            <li class="bg-surface-2 !p-4 rounded-md" v-for="subscription in (subscriptionsFiltered)"
              :key="subscription.package._id">
              <header class="flex flex-wrap items-center gap-x-2">
                <h3 class="text-lg">{{ subscription.package.name }}</h3>
                <Badge pill class="!inline-flex" :title="activeTab === 'active' ? 'Active' : 'Expired'"
                  :type="activeTab === 'active' ? 'online' : 'error'" />
                <Badge class="md:ml-auto" v-if="isBundled(subscription)" title="Bundled" />
                <div
                  class="md:ml-auto text-sm text-surface-8 flex items-center gap-x-2 order-last md:order-none w-full md:w-auto"
                  v-else>
                  <span>{{ activeTab === 'active' ? 'Valid until' : 'Expired'
                  }}</span>
                  <span class="text-sm" :class="activeTab === 'active' ? 'text-success' : 'text-danger'">{{
                    formatedDate(subscription.cend) }}</span>
                  <FormButton class="!ml-auto md:ml-0" size="sm" :label="activeTab === 'active' ? 'Change' : 'Renew'"
                    to="../subscribe?category=live" />
                  <FormButton size="sm" v-if="activeTab === 'active' && !subscription.unsubscribed" type="secondary"
                    label="Cancel" @click="requestUnsubscribe(subscription)" />
                  <FormButton size="sm" v-if="activeTab === 'active' && subscription.unsubscribed" disabled
                    type="secondary" label="Unsubscribed" />
                </div>
                <p v-if="!isBundled(subscription)" class="text-surface-8 font-medium text-sm w-full">${{
                  subscription.package.baseCharge }}/{{ subscriptionCirle(subscription) }}</p>
                <p class="w-full">
                  <Badge class="!inline-flex" :title="subCategory(subscription)" />
                </p>
              </header>
              <template v-if="subscription.package?.category !== 'ott'">
                <hr class="my-6">
                <h3 class="text-surface-8 !mb-2">Usage quota</h3>
                <dl class="grid grid-cols-2 gap-3 lg:w-[480px]">
                  <dt v-if="getSubscriptionDefProp(subscription, 'streams')"
                    class="text-tiny text-surface-8 capitalize font-normal">
                    Streams
                  </dt>
                  <dd class="grid grid-cols-2 gap-3 text-tiny">
                    {{ getSubscriptionDefProp(subscription, 'streams') }} assigned
                  </dd>
                  <template v-for="(usageItem, index) in subscription.usage" :key="index">
                    <dt class="text-tiny text-surface-8 capitalize font-normal col-span-2 md:col-auto">
                      {{ index === 'phoneAlerts' ? 'Phone Alerts' : index }}
                    </dt>
                    <dd class="grid grid-cols-2 gap-3 text-tiny  col-span-2 md:col-auto">
                      <div
                        class="bg-surface-3 basis-1/2 flex flex-wrap items-center content-center justify-center rounded-md p-2">
                        <p class="text-base uppercase">{{ usageItem.value }} {{ usageItem.unit }}</p>
                        <h4 class="w-full text-center text-surface-7">Usage</h4>
                      </div>
                      <div
                        class="bg-surface-3 basis-1/2 flex flex-wrap items-center content-center justify-center rounded-md p-2">
                        <p class="text-base uppercase">{{ assignedFormatted(getSubscriptionDefProp(subscription, index),
                          index) }}</p>
                        <h4 class="w-full text-center text-surface-7">Assigned</h4>
                      </div>
                    </dd>
                  </template>
                </dl>
              </template>
            </li>
          </ul>
        </section>
        <section v-else class="!border !border-surface-3 w-96 mx-auto mt-8 p-6 rounded-md">
          <p class="text-center mb-2 font-medium text-surface-9 text-lg">It's empty here :(</p>
          <p v-if="activeTab === 'expired'" class="text-dark-6 text-sm text-center mb-0">You don't have any expired
            subscription
            plan</p>
          <p v-else class="text-center">
            <FormButton size="sm" to="/subscribe?category=live"
              class="text-surface-7 hover:text-white text-sm hover:underline mb-0">Checkout
              Plans</FormButton>
          </p>
        </section>
        <section v-if="invoiceItems?.length > 0">
          <ul class="mt-6 divide divide-y divide-surface-3">
            <li class="flex text-sm text-surface-6 !py-2">
              <h5 class="basis-3/12">Date</h5>
              <h5 class="basis-6/12">Product</h5>
              <h5 class="basis-2/12">Amount</h5>
              <h5 class="basis-1/12 text-right">Invoice</h5>
            </li>
            <li v-for="(invoice, index) in invoiceItems" :key="index" class="flex text-surface-9 items-center !py-2">
              <span class="basis-3/12">{{ invoice.date }}</span>
              <span class="basis-6/12">{{ invoice.products }}</span>
              <span class="basis-2/12">{{ invoice.amount }}</span>
              <span class="basis-1/12 text-right">
                <FormButton size="sm" isIcon :href="invoice.invoice" type="secondary">
                  <icon-base>
                    <IconDownload />
                  </icon-base>
                </FormButton>
              </span>
            </li>
          </ul>
        </section>
      </main>
    </div>
    <div class="lg:mt-24" v-else>
      <h3 class="text-lg text-center text-surface-9">Moderators don't have access to the Billing page.</h3>
      <p class="text-surface-6 text-center text-sm mb-2">Only the team owner and admins can access this page. Contact
        your
        team owner (<a class="underline" :href="'mailto:' + store.state.User.guestData.ownersEmail">{{
          store.state.User.guestData.ownersEmail }}</a>)
        for more information.</p>
      <p class="text-center">
        <FormButton to="/">
          Go to Livestreams
        </FormButton>
      </p>
    </div>
    <subscription-cancel-modal modal-id="modal-unsubscribe-package"
      :message="configurableSubscription ? 'Are you sure you want to unsubscribe from \'' + getSubscriptionName() + '\' package?' : ''"
      @modal-confirm="unsubscribeSubCategory">
    </subscription-cancel-modal>
    <alert-modal modal-id="modal-change-payment-info-failed" buttonAlignment="text-right"
      message="We only support to change credit card details for active account at the moment. If you are using Paypal or other payment type, please contact our support for help."
      ok-text="Got it" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
