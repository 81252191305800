<script setup>
import { computed } from 'vue'
import Spinner from '../ui/Spinner.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'md',
  },
  isIcon: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: '_blank'
  },
  customClasses: {
    type: String,
  },
  to: {
    type: String,
  },
  href: {
    type: String,
  },
  onClick: Function,
  ref: {
    type: String,
  }
})

const computedTypeClass = computed(() => {
  switch (props.type) {
    case 'main':
      return 'bg-main hover:bg-main-light text-surface-text-2 hover:text-white'
    case 'secondary':
      return props.isIcon ? 'text-surface-text-1 hover:text-white hover:text-white ring-1 ring-surface-4 hover:text-white hover:ring-surface-6' : 'text-surface-text-1 ring-1 ring-surface-4 bg-transparent hover:bg-surface-2 hover:text-white hover:ring-surface-6'
    case 'link':
      return 'text-surface-text-1 hover:text-white disabled:text-surface-6'
    case 'light':
      return props.isIcon ? 'text-surface-text-1 hover:text-white hover:text-white hover:text-white' : 'text-surface-text-1 bg-transparent hover:bg-surface-2 hover:text-white'
    case 'white':
      return 'text-surface-1 bg-surface-9 hover:bg-white hover:text-black'
    case 'success':
      return 'success'
    case 'danger':
      return 'ring-1 ring-surface-4 hover:ring-error-light hover:text-error-light focus:ring-1 focus:ring-surface-4'
    case 'main-outline':
      return 'text-main ring-1 ring-main !bg-transparent hover:!bg-main-light hover:text-white hover:ring-main-light'
    default: return '!border-main bg-main hover:border-main-light bg:main-light'
  }
})

const computedSizeClass = computed(() => {
  switch (props.size) {
    case 'lg':
      return props.isIcon ? 'h-10 w-10 text-base' : 'h-10 text-base'
    case 'md':
      return props.isIcon ? 'h-8 w-8  text-tiny' : 'h-8 text-tiny'
    case 'sm':
      return props.isIcon ? 'h-7 w-7 text-tiny' : 'h-7 text-tiny'
    case 'xs':
      return props.isIcon ? 'h-6 w-6 text-tiny' : 'h-6 text-tiny'
    default: return props.isIcon ? 'h-6 w-6' : 'h-8'
  }
})

const computedClass = computed(() => {
  const baseClass = ['button !border-none form-button inline-flex items-center !rounded-md leading-none text-nowrap box-border font-medium hover:no-underline disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-7 max-w-full truncate grow-0', props.isIcon ? 'p-0 justify-center' : 'py-2 !px-3', (props.isIcon || props.type === 'link') ? 'border-none' : 'border']
  const result = []
  if (props.fullWidth) result.push('!w-full')
  result.push(baseClass.join(' '), computedSizeClass.value, computedTypeClass.value)
  return props.customClasses ? props.customClasses : result.join(' ')
})
</script>

<template>
  <button :ref="props.ref" v-if="!to && !href" @click="onClick" :class="computedClass" :disabled="disabled">
    <template v-if="!isLoading">
      {{ label }}
      <slot />
    </template>
    <Spinner v-else />
  </button>
  <router-link v-else-if="to" :to="to" :class="computedClass" :disabled="disabled">
    <template v-if="!isLoading">
      {{ label }}
      <slot />
    </template>
    <Spinner v-else />
  </router-link>
  <a v-else-if="href" :target="props.target" :class="computedClass" :disabled="disabled" :href="href">
    <template v-if="!isLoading">
      {{ label }}
      <slot />
    </template>
    <Spinner v-else />
  </a>
</template>

<style>
@layer tailwind {
  button[disabled].bg-main {
    @apply !border-surface-4 text-surface-6
  }
}
</style>
