<script setup>
import { defineProps, ref, onMounted, watch } from 'vue'
import TabGroup from '../Atoms/TabGroup.vue'
import TabItem from '../Atoms/TabItem.vue'

const emit = defineEmits({
  progress: (value) => { return value }
})

const productType = defineModel('productType')

// Refs


//Hooks
onMounted(() => {
  
})

//Methods
const setActiveTab = (tabName) => productType.value = tabName
const customClasses = (type) => {
 return type === productType.value
 ? 'rounded-full text-base font-medium !px-6 leading-none h-full bg-main transition-all'
 : 'rounded-full text-base font-medium !px-6 leading-none h-full hover:ring-1 hover:ring-white hover:text-white transition-all'
}
</script>

<template>
  <TabGroup type="custom" customClasses="flex gap-4 p-1 !border !border-surface-2 rounded-full h-14">
    <TabItem :isActive="productType === 'streaming'" label="Live Streaming Pricing" @click="setActiveTab('streaming')" :customClasses="customClasses('streaming')" />
    <TabItem :isActive="productType === 'ott'" label="OTT Pricing" @click="setActiveTab('ott')" :customClasses="customClasses('ott')" />
  </TabGroup>
</template>