<script setup>
import { ref, computed, onMounted } from 'vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconPlay from '../../components/icon/IconPlay.vue';
import IconBook from '../../components/icon/IconBook.vue';
import IconCalendarPlus from '../../components/icon/IconCalendarPlus.vue';
import DemoVideoModal from '../../components/modals/DemoVideoModal.vue';
import Subscribe from '../Subscribe.vue';

const demoModal = ref()

const showDemoModal = () => {
	demoModal.value.show()
}

</script>

<template>
	<div class="container">
		<main>
			<header class="flex items-center mb-3 mt-5">
				<h1 class="text-2xl">
					OTT Apps
				</h1>
			</header>
			<article class="flex flex-col">
				<h2 class="text-surface-6 text-sm text-center">White-labeled mobile and TV apps for your brand on iOS, Android,
					Roku, Android TV, and Apple TV.</h2>
				<p class="text-surface-6 text-sm text-center leading-6 text-balance">Seamless billing, email automation, and
					advanced features—all in one platform.</p>
				<p class="text-surface-6 text-sm text-center leading-6 mb-6 text-balance">Book a meeting with us today to get
					started!</p>
				<div class="flex justify-center gap-x-2">
					<FormButton
						href="https://docs.castr.com/en/articles/5483773-introducing-castr-ott-create-your-own-branded-streaming-platform"
						target="_blank" class="flex justify-center gap-x-2 mb-3 w-48" type="secondary">
						<icon-base>
							<icon-book />
						</icon-base>
						How OTT Apps work
					</FormButton>
					<FormButton @click="showDemoModal" class="flex justify-center gap-x-2 w-48 mb-3" type="secondary">
						<icon-base>
							<icon-play />
						</icon-base>
						OTT Apps demo
					</FormButton>
					<FormButton
						href="https://calendly.com/castr/sales?utm_source=header&utm_medium=website&utm_campaign=header-btn-1"
						target="_blank" class="flex justify-center gap-x-2 w-48">
						<icon-base classIcon="inline -mt-1" iconColor="transparent">
							<icon-calendar-plus />
						</icon-base>
						Book a meeting
					</FormButton>
				</div>
				<Subscribe/>
			</article>
		</main>
		<demo-video-modal ref="demoModal" videoid="cm4gtj1il1uys124hh7g8q43x" />
	</div>
</template>

<style></style>
